import React, { useState, useEffect } from "react";
import { Container } from "../../global";
export const RiskWarning = (props) => {
    const [textValue, setTextValue] = useState("");
    useEffect(() => {
        setTextValue(props.text);
    }, [props.text]);
    return (React.createElement("div", { className: "risk-warning" },
        React.createElement(Container, null,
            React.createElement("div", { dangerouslySetInnerHTML: { __html: textValue } }))));
};
