import * as React from "react";
import { Logo } from "../../Logo/Logo";
import { Container, ContainerFW } from "../../global";
import { Img } from "../../Img";
export const SocialContacts = (props) => {
    const mouseClickEvents = ["mousedown", "click", "mouseup"];
    function simulateMouseClick(element) {
        mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })));
    }
    const simulateMouseClickOnZendeskChatIcon = React.useCallback((e) => {
        const el = e.target.closest("A");
        if (el && e.currentTarget.contains(el)) {
            const element = document.querySelector("a[class=\"chat-toggle\"]");
            simulateMouseClick(element);
        }
    }, []);
    return (React.createElement("div", { className: "social-contacts" },
        React.createElement(ContainerFW, null,
            React.createElement("div", { className: "social-contacts__divider-line" }),
            React.createElement(Container, null,
                React.createElement("div", { className: "social-contacts__logo-and-social" },
                    React.createElement("div", { className: "social-contacts__logo" },
                        React.createElement(Logo, { imageUrl: props.logoImageUrl, alt: props.logoImageAlt, logoHref: props.logoHref })),
                    React.createElement("div", { className: "social-contacts__live-chat__mobile" },
                        React.createElement("div", { className: "social-contacts__live-chat__text" },
                            React.createElement("p", { dangerouslySetInnerHTML: {
                                    __html: props.liveChatText ?? "",
                                } }))),
                    React.createElement("div", { className: "social-contacts__social" }, props.icons &&
                        props.icons.map((item, i) => (React.createElement("a", { key: `social-contacts__social-${i}`, href: item.iconLink, className: "social-contacts__social__item" },
                            React.createElement(Img, { className: "social-contacts__logo-image", src: item.iconUrl, alt: item.iconAlt }))))))),
            React.createElement("div", { className: "social-contacts__divider-line" })),
        React.createElement(Container, null,
            React.createElement("div", { className: "social-contacts__live-chat" },
                React.createElement("div", { className: "social-contacts__live-chat__text" },
                    React.createElement("p", { onClick: simulateMouseClickOnZendeskChatIcon, dangerouslySetInnerHTML: {
                            __html: props.liveChatText ?? "",
                        } }))))));
};
