import * as React from "react";
import { useState } from "react";
import { pageViewStyle } from "../../../../pageViewStyle";
import { IconChevronDown } from "../../../svgIcons";
import { TradingViewSmallButton } from "../../../TradingViewSmallButton";
export const AccordionMenu = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    React.useEffect(() => {
        const divaceSize = pageViewStyle.getView();
        if (divaceSize === "large") {
            setIsActive(true);
            setIsDesktop(true);
        }
    });
    const handleToggle = React.useCallback(() => {
        if (isDesktop === false) {
            setIsActive(!isActive);
        }
    }, [isActive]);
    return (React.createElement("div", { className: "accordion-menu__menu__block-links-container" },
        React.createElement("ul", { className: "accordion-menu__menu__block-links" },
            React.createElement("li", { className: "accordion-menu__menu__block-links__category", onClick: handleToggle },
                React.createElement("div", null, props.title),
                React.createElement("div", { className: "accordion-menu__menu__block-links__category__arrow" }, IconChevronDown({}))),
            isActive && props.accordionData.map((el, y) => (React.createElement("li", { key: y, className: "accordion-menu__menu__block-links__category__items" },
                React.createElement("a", { href: el.url }, el.label))))),
        props.haveTVButton && React.createElement(TradingViewSmallButton, { urlLink: props.buttonTVUrlLink === undefined ? "" : props.buttonTVUrlLink, imageUrl: props.buttonTVImageUrl === undefined ? "" : props.buttonTVImageUrl, imageAlt: props.buttonTVImageAlt === undefined ? "" : props.buttonTVImageAlt })));
};
