import * as React from "react";
import throttle from "lodash.throttle";
import { arrowIcon } from "../../../Components/Cards/WideRangeCardDeck/WideRangeCard";
export const ScrollToTopButton = () => {
    const scrollToTopBtnRef = React.useRef(null);
    const setScrollButtonDisplay = React.useCallback(() => {
        if (!scrollToTopBtnRef?.current) {
            return;
        }
        const button = scrollToTopBtnRef.current;
        const buttonTop = button.getBoundingClientRect().top + document.documentElement.scrollTop;
        const displayValue = buttonTop >= window.innerHeight ? "flex" : "none";
        button.style.display = displayValue;
    }, []);
    const scrollTopHandler = React.useCallback(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    React.useEffect(() => {
        const scrollListener = () => {
            setScrollButtonDisplay();
        };
        const resizeListener = () => {
            scrollListener();
        };
        setScrollButtonDisplay();
        const throttledScrollListener = throttle(scrollListener, 100);
        window.addEventListener("scroll", throttledScrollListener);
        const throttledResizeListener = throttle(resizeListener, 100);
        window.addEventListener("resize", throttledResizeListener);
        return () => {
            window.removeEventListener("scroll", throttledScrollListener);
            window.removeEventListener("rezize", throttledResizeListener);
        };
    }, [setScrollButtonDisplay]);
    return React.createElement("div", { ref: scrollToTopBtnRef, onClick: scrollTopHandler, className: "scroll-to-top" },
        arrowIcon(),
        "TOP");
};
